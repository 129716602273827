<template>
  <div class="home page-wrapper">
    <div class="container-xl">
      <!-- Page title -->
      <div class="page-header d-print-none">
        <div class="row align-items-center">
          <div class="col">
            <!-- Page pre-title -->
            <div class="page-pretitle">
              {{ $auth.activeCompany.companyName }}
            </div>
            <h2 class="page-title">Settings</h2>
          </div>
        </div>
      </div>
    </div>
    <div class="page-body">
      <div class="container-xl" v-if="loaded">
        <div class="row row-deck row-cards">
          <div class="card card-md">
            <div class="card-body">
              <div class="row">
                <div class="markdown">
                  <p class="h2">Account Settings</p>
                  <p>
                    Your Account Settings are for internal use only. This
                    information will not appear on your Company Profile page.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div class="card card-md" v-if="!this.filePosted">
            <div class="card-body">
              <div class="row">
                <div class="col-12 mb-3">
                  <p class="h5 pb-3">
                    (<strong>Bold</strong> fields are required)
                  </p>

                  <div class="row mb-3">
                    <div class="col-md-1">
                      <label class="form-label">Prefix</label>
                      <select class="form-control form-select" v-model="prefix">
                        <option v-bind:value="-1">Select</option>
                        <option
                          v-for="prefix in prefixDTOs"
                          :key="prefix.prefixid"
                        >
                          {{ prefix.prefix }}
                        </option>
                      </select>
                    </div>
                    <div class="col-md-2">
                      <label class="form-label font-weight-bold"
                        >First Name</label
                      >
                      <input
                        :class="[
                          'form-control',
                          this.formErrors.firstname ? 'is-invalid' : '',
                        ]"
                        id="validateFirstName"
                        ref="firstname"
                        v-model="firstname"
                        required
                        @blur="validate('firstname')"
                      />
                      <div class="invalid-feedback">
                        <small>{{ this.firstnameerrormessage }}</small>
                      </div>
                    </div>
                    <div class="col-md-1">
                      <label class="form-label">MI</label>
                      <input class="form-control" v-model="middleinitial" />
                    </div>
                    <div class="col-md-2">
                      <label class="form-label font-weight-bold"
                        >Last Name</label
                      >
                      <input
                        :class="[
                          'form-control',
                          this.formErrors.lastname ? 'is-invalid' : '',
                        ]"
                        id="validateLastName"
                        ref="lastname"
                        v-model="lastname"
                        required
                        @blur="validate('lastname')"
                      />
                      <div class="invalid-feedback">
                        <small>{{ this.lastnameerrormessage }}</small>
                      </div>
                    </div>
                    <div class="col-md-1">
                      <label class="form-label">Suffix</label>
                      <select class="form-control form-select" v-model="suffix">
                        <option v-bind:value="-1">Select</option>
                        <option
                          v-for="suffix in suffixDTOs"
                          :key="suffix.suffixid"
                        >
                          {{ suffix.suffix }}
                        </option>
                      </select>
                    </div>
                  </div>

                  <div class="row mb-3">
                    <div class="col-md-6">
                      <label class="form-label mt-1 font-weight-bold"
                        >Title:</label
                      >
                      <input
                        :class="[
                          'form-control',
                          this.formErrors.title ? 'is-invalid' : '',
                        ]"
                        id="validateTitle"
                        ref="title"
                        v-model="title"
                        required
                        @blur="validate('title')"
                      />
                      <div class="invalid-feedback">
                        <small>{{ this.titleerrormessage }}</small>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-6 mb-3">
                    <label class="form-label font-weight-bold"> Country:</label>
                    <select
                      :class="[
                        'form-control form-select country-select',
                        this.formErrors.country ? 'is-invalid' : '',
                      ]"
                      id="validateCountry"
                      ref="country"
                      v-model="country"
                      required
                      @blur="validate('country')"
                    >
                      <option v-bind:value="-1">Select</option>
                      <option
                        v-for="country in countryDTOs"
                        :key="country.countryid"
                      >
                        {{ country.country }}
                      </option>
                    </select>
                    <div class="invalid-feedback">
                      <small>{{ this.countryerrormessage }}</small>
                    </div>
                  </div>

                  <div class="col-md-6 mb-3" v-if="showRegion">
                    <label class="form-label font-weight-bold">Region:</label>
                    <input class="form-control" v-model="region" />
                  </div>

                  <div class="row mb-3">
                    <div class="col-md-6">
                      <label class="form-label mt-1 font-weight-bold"
                        >Address (line 1):</label
                      >
                      <input
                        :class="[
                          'form-control',
                          this.formErrors.address1 ? 'is-invalid' : '',
                        ]"
                        id="validateAddress1"
                        ref="address1"
                        v-model="address1"
                        required
                        @blur="validate('address1')"
                      />
                      <div class="invalid-feedback">
                        <small>{{ this.address1errormessage }}</small>
                      </div>
                    </div>
                  </div>
                  <div class="row mb-3">
                    <div class="col-md-6">
                      <label class="form-label mt-1">Address (line 2):</label>
                      <input class="form-control" v-model="address2" />
                    </div>
                  </div>

                  <div class="row mb-3">
                    <div class="col-md-2">
                      <label class="form-label font-weight-bold">City:</label>
                      <input
                        :class="[
                          'form-control',
                          this.formErrors.city ? 'is-invalid' : '',
                        ]"
                        id="validateCity"
                        ref="city"
                        v-model="city"
                        required
                        @blur="validate('city')"
                      />
                      <div class="invalid-feedback">
                        <small>{{ this.cityerrormessage }}</small>
                      </div>
                    </div>
                    <div class="col-md-2">
                      <label class="form-label font-weight-bold">State:</label>
                      <select
                        :class="[
                          'form-control form-select',
                          this.formErrors.state ? 'is-invalid' : '',
                        ]"
                        id="validateState"
                        ref="state"
                        v-model="state"
                        required
                        @blur="validate('state')"
                      >
                        <option v-bind:value="-1">Select</option>
                        <option v-for="state in stateDTOs" :key="state.stateid">
                          {{ state.state }}
                        </option>
                      </select>
                      <div class="invalid-feedback">
                        <small>{{ this.stateerrormessage }}</small>
                      </div>
                    </div>
                    <div class="col-md-2">
                      <label class="form-label font-weight-bold"
                        >Postal Code:</label
                      >
                      <input
                        :class="[
                          'form-control',
                          this.formErrors.postalcode ? 'is-invalid' : '',
                        ]"
                        id="validatePostalCode"
                        ref="postalcode"
                        v-model="postalcode"
                        required
                        @blur="validate('postalcode')"
                      />
                      <div class="invalid-feedback">
                        <small>{{ this.postalcodeerrormessage }}</small>
                      </div>
                    </div>
                  </div>

                  <div class="row mb-3">
                    <div class="col-md-2">
                      <label class="form-label font-weight-bold">Phone:</label>
                      <input
                        :class="[
                          'form-control',
                          this.formErrors.phone ? 'is-invalid' : '',
                        ]"
                        id="validatePhone"
                        ref="phone"
                        v-model="phone"
                        required
                        @blur="validate('phone')"
                      />
                      <div class="invalid-feedback">
                        <small>{{ this.phoneerrormessage }}</small>
                      </div>
                    </div>
                    <div class="col-md-1">
                      <label class="form-label">Ext:</label>
                      <input class="form-control" v-model="phoneext" />
                    </div>
                  </div>

                  <div class="row mb-3">
                    <div class="col-md-2">
                      <label class="form-label">Phone (Alt):</label>
                      <input
                        :class="[
                          'form-control',
                          this.formErrors.phone ? 'is-invalid' : '',
                        ]"
                        v-model="phone2"
                      />
                    </div>
                    <div class="col-md-1">
                      <label class="form-label">Ext:</label>
                      <input class="form-control" v-model="phone2ext" />
                    </div>
                  </div>

                  <div class="col-md-2">
                    <label class="form-label mt-3">Phone (Cell):</label>
                    <input class="form-control" v-model="cellphone" />
                  </div>

                  <div class="col-md-2">
                    <label class="form-label mt-3">Fax:</label>
                    <input class="form-control" v-model="fax" />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="card card-md" v-if="!this.filePosted">
            <div class="card-body">
              <div class="row">
                <div class="col-12 d-flex">
                  <div class="col-12 mb-3">
                    <p class="h2">User Information</p>

                    <div class="row mb-3 mt-3">
                      <div class="col-md-5 p-right">
                        <label class="form-label mt-1 font-weight-bold"
                          >Email Address:</label
                        >
                        <input
                          :class="[
                            'form-control d-inline leaveroomforlabel',
                            this.formErrors.emailaddress ? 'is-invalid' : '',
                          ]"
                          id="validateEmailAddress"
                          ref="emailaddress"
                          v-model="emailaddress"
                          required
                          @blur="validate('emailaddress')"
                        />
                        <div class="d-inline leaveblank">
                          <span id="tooltip-target-2">
                            (This is your username)
                          </span>
                        </div>
                        <div class="invalid-feedback">
                          <small>{{ this.emailaddresserrormessage }}</small>
                        </div>
                      </div>
                    </div>

                    <div class="row mb-3">
                      <div class="col-md-5 p-right">
                        <label class="form-label mt-3">Password:</label>
                        <input
                          :class="[
                            'form-control d-inline leaveroomforlabel',
                            this.formErrors.password ? 'is-invalid' : '',
                          ]"
                          type="password"
                          id="validatepassword"
                          ref="password"
                          autocomplete="off"
                          v-model="password"
                          required
                          @blur="validate('password')"
                        />
                        <div class="d-inline leaveblank">
                          <span id="tooltip-target-2">
                            (Leave blank unless changing)
                          </span>
                        </div>
                        <div class="invalid-feedback">
                          <small>{{ this.passworderrormessage }}</small>
                        </div>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-md-5 p-right">
                        <label class="form-label mt-3">Confirm Password:</label>
                        <input
                          :class="[
                            'form-control d-inline leaveroomforlabel',
                            this.formErrors.confirmpassword ? 'is-invalid' : '',
                          ]"
                          type="password"
                          id="validateconfirmpassword"
                          ref="confirmpassword"
                          autocomplete="off"
                          v-model="confirmpassword"
                          required
                          @blur="validate('confirmpassword')"
                        />
                        <div class="invalid-feedback">
                          <small>{{ this.confirmpassworderrormessage }}</small>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="card card-md">
            <div class="card-body">
              <div class="row">
                <div v-if="reload">
                  <div class="spinner-border mt-2" role="status"></div>
                </div>
                <div class="col-12 card card-md" v-if="postSuccess">
                  <div class="card-status-top bg-success"></div>
                  <div class="card-body">
                    <div class="col-12">
                      <p class="h3">Your settings have been saved</p>
                      <div class="text-left">
                        <button
                          class="btn btn-primary"
                          @click.prevent="reloadSettings"
                        >
                          Reload
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-12 d-flex" v-else>
                  <div class="col-12">
                    <p class="h2">Leads</p>
                    <p class="h4 pb-1">
                      Choose which of your contacts should receive leads.
                    </p>
                    <div class="mt-3">
                      <div
                        class="custom-control mt-2"
                        v-for="item in leadContactSelect"
                        v-bind:key="item.value"
                      >
                        <div class="form-check">
                          <input
                            class="form-check-input"
                            type="radio"
                            v-model="filter.selected_contact"
                            :value="item.value"
                            :id="item.value"
                          />
                          <label :for="item.value">{{ item.text }}</label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="mt-4" v-if="showSaveCancelButtons">
                  <div class="d-inline" style="margin-right: 15px">
                    <button
                      @click.prevent="saveAction"
                      class="btn btn-block btn-primary"
                    >
                      Save
                    </button>
                  </div>
                  <div class="d-inline">
                    <a
                      href="#"
                      class="btn btn-secondary"
                      @click.prevent="cancelEdit"
                    >
                      Cancel
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="container-xl" v-else>
        <div class="row">
          <div class="col">
            <h3>Loading settings...</h3>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.table .text-right {
  text-align: right;
}
.table .text-left {
  text-align: left;
}
.table .text-center {
  text-align: center;
}
.table-responsive {
  margin-bottom: 0px;
}
.alert {
  padding-top: 0.4375rem;
  padding-right: 1rem;
  padding-bottom: 0.4375rem;
  padding-left: 1rem;
}
.description {
  width: 470px;
  height: 200px;
  resize: none;
}

.leaveroomforlabel {
  width: calc(100% - 208px) !important;
}
.p-right {
  padding-right: 0px !important;
}

.leaveblank {
  margin-left: 3px;
}
</style>

<script>
import SettingsService from "../services/SettingsService";
import TrackingService from "../services/TrackingService";

export default {
  name: "Settings",
  components: {},
  data: () => {
    return {
      loaded: false,
      reload: false,
      sortdir: "asc",
      settingDTO: [],
      countryDTOs: [],
      stateDTOs: [],
      prefixDTOs: [],
      suffixDTOs: [],
      contactDTOs: [],
      existingContacts: [],
      existingSettings: [],
      prefix: "",
      firstname: "",
      middleinitial: "",
      lastname: "",
      suffix: "",
      title: "",
      address1: "",
      address2: "",
      city: "",
      state: "",
      country: "",
      region: "",
      postalcode: "",
      phone: "",
      phoneext: "",
      phone2: "",
      phone2ext: "",
      cellphone: "",
      fax: "",
      emailaddress: "",
      password: "",
      confirmpassword: "",
      originalpassword: "",
      emailaddresserrormessage: "Please enter an emailaddress",
      passworderrormessage: "Please enter a password",
      confirmpassworderrormessage: "Confirm password does not match password",
      firstnameerrormessage: "Please enter a firstname",
      lastnameerrormessage: "Please enter a lastname",
      titleerrormessage: "Please enter a title",
      address1errormessage: "Please enter a valid address",
      cityerrormessage: "Please enter a city",
      countryerrormessage: "Please select a country",
      stateerrormessage: "Please select a state",
      postalcodeerrormessage: "Please enter a postalcode",
      phoneerrormessage: "Please enter a phone number",
      actionlabel: "Post",
      formErrors: {},
      fileName: "",
      fileUrl: "",
      fileError: "",
      filePosted: false,
      custom: false,
      rows: 0,
      postSuccess: false,
      postError: false,
      leadContactSelect: [
        {
          text: "",
          value: null,
        },
      ],
      filter: {
        selected_categoryids: [],
        selected_contact: [],
      },
    };
  },
  methods: {
    updateSelectedContact(selected) {
      this.$set(this.filter, "selected_contact", selected);
    },
    getFormattedDate(thedate) {
      let year = new Date(thedate).getFullYear();
      let month = (1 + new Date(thedate).getMonth())
        .toString()
        .padStart(2, "0");
      let day = new Date(thedate).getDate().toString().padStart(2, "0");
      return month + "/" + day + "/" + year;
    },
    sort: function (s) {
      //if s == current sort, reverse
      if (s === this.sortcol) {
        this.sortdir = this.sortdir === "asc" ? "desc" : "asc";
      }
      this.sortcol = s;
    },
    validate(item) {
      if (item === "all" || item === "firstname") {
        if (this.firstname == "" || this.firstname == null) {
          this.$set(this.formErrors, "firstname", true);
        } else {
          this.$set(this.formErrors, "firstname", false);
        }
      }
      if (item === "all" || item === "lastname") {
        if (this.lastname == "" || this.lastname == null) {
          this.$set(this.formErrors, "lastname", true);
        } else {
          this.$set(this.formErrors, "lastname", false);
        }
      }
      if (item === "all" || item === "title") {
        if (this.title == "" || this.title == null) {
          this.$set(this.formErrors, "title", true);
        } else {
          this.$set(this.formErrors, "title", false);
        }
      }
      if (item === "all" || item === "address1") {
        if (this.address1 == "" || this.address1 == null) {
          this.$set(this.formErrors, "address1", true);
        } else {
          this.$set(this.formErrors, "address1", false);
        }
      }
      if (item === "all" || item === "city") {
        if (this.city == "" || this.city == null) {
          this.$set(this.formErrors, "city", true);
        } else {
          this.$set(this.formErrors, "city", false);
        }
      }
      if (item === "all" || item === "country") {
        if (this.country == "" || this.country == null) {
          this.$set(this.formErrors, "country", true);
        } else {
          this.$set(this.formErrors, "country", false);
        }
      }
      if (item === "all" || item === "state") {
        if (this.state == "" || this.state == null) {
          this.$set(this.formErrors, "state", true);
        } else {
          this.$set(this.formErrors, "state", false);
        }
      }
      if (item === "all" || item === "postalcode") {
        if (this.postalcode == "" || this.postalcode == null) {
          this.$set(this.formErrors, "postalcode", true);
        } else {
          this.$set(this.formErrors, "postalcode", false);
        }
      }
      if (item === "all" || item === "phone") {
        if (this.phone == "" || this.phone == null) {
          this.$set(this.formErrors, "phone", true);
        } else {
          this.$set(this.formErrors, "phone", false);
        }
      }

      if (item === "all" || item === "emailaddress") {
        if (this.emailaddress == "" || this.emailaddress == null) {
          this.$set(this.formErrors, "emailaddress", true);
        } else {
          this.$set(this.formErrors, "emailaddress", false);
        }
      }
      if (item === "all" || item === "password") {
        if (this.password == "" || this.password == null) {
          this.$set(this.formErrors, "password", true);
        } else {
          this.$set(this.formErrors, "password", false);
        }
      }
      if (item === "all" || item === "confirmpassword") {
        if (
          this.confirmpassword == "" ||
          this.confirmpassword == null ||
          this.confirmpassword != this.password
        ) {
          this.$set(this.formErrors, "confirmpassword", true);
        } else {
          this.$set(this.formErrors, "confirmpassword", false);
        }
      }
    },
    validUrl(url) {
      var pattern = new RegExp(
        "^(https?:\\/\\/)?" + // protocol
          "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
          "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
          "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
          "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
          "(\\#[-a-z\\d_]*)?$",
        "i"
      ); // fragment locator
      return !!pattern.test(url);
    },
    clearForm() {
      // clear our form
      this.postError = "";
      this.filePosted = false;
      this.postSuccess = false;
      this.postResult = [];
    },
    saveAction() {
      this.saveSettings();
    },
    cancelEdit() {
      // mark as reload
      this.reload = true;

      // clear form
      this.clearForm();

      // load contacts
      this.getContacts();

      // set properties
      this.prefix = this.existingSettings.prefix;
      this.firstname = this.existingSettings.firstName;
      this.middleinitial = this.existingSettings.middleInitial;
      this.lastname = this.existingSettings.lastName;
      this.suffix = this.existingSettings.suffix;
      this.title = this.existingSettings.title;
      this.address1 = this.existingSettings.address;
      this.address2 = this.existingSettings.address2;
      this.city = this.existingSettings.city;
      this.state = this.existingSettings.state;
      this.postalcode = this.existingSettings.postalCode;
      this.country = this.existingSettings.country;
      this.phone = this.existingSettings.phone;
      this.phoneext = this.existingSettings.phoneExt;
      this.phone2 = this.existingSettings.phone2;
      this.phone2ext = this.existingSettings.phone2Ext;
      this.cellphone = this.existingSettings.cellPhone;
      this.tollfree = this.existingSettings.tollFree;
      this.fax = this.existingSettings.fax;
      this.password = this.existingSettings.password;
      this.confirmpassword = this.existingSettings.password;
      this.originalpassword = this.existingSettings.password;
      this.emailaddress = this.existingSettings.emailAddress;

      // mark reload as complete
      this.reload = false;

      // revalidate
      this.validate("all");

      // force window scroll
      window.scrollTo(0, 0);
    },
    scrollToBottom(anchorname) {
      // get reference to tradeshow div
      const el = this.$el.querySelector("#" + anchorname);

      if (el) {
        // force smooth window scroll to bottom
        setTimeout(function () {
          el.scrollIntoView({ behavior: "smooth" });
        }, 40);
      }
    },
    async reloadSettings() {
      // clear form
      this.clearForm();

      // load account settings
      this.getSettings();

      // force window scroll
      window.scrollTo(0, 0);
    },
    async getCountries() {
      let company = this.$auth.activeCompany;

      let companyFilter = {
        Company: { Companyid: company.companyId },
      };

      // make service call
      this.countryDTOs = await SettingsService.getCountries(companyFilter);
    },
    async getStates() {
      let company = this.$auth.activeCompany;

      let companyFilter = {
        Company: { Companyid: company.companyId },
      };

      // make service call
      this.stateDTOs = await SettingsService.getStates(companyFilter);
    },
    async getPrefixes() {
      let company = this.$auth.activeCompany;

      let companyFilter = {
        Company: { Companyid: company.companyId },
      };

      // make service call
      this.prefixDTOs = await SettingsService.getPrefixes(companyFilter);
    },
    async getSuffixes() {
      let company = this.$auth.activeCompany;

      let companyFilter = {
        Company: { Companyid: company.companyId },
      };

      // make service call
      this.suffixDTOs = await SettingsService.getSuffixes(companyFilter);
    },
    async getContacts() {
      let company = this.$auth.activeCompany;

      let settingsFilter = {
        Company: { Companyid: company.companyId },
      };

      // make service call
      let contactsDTO = await SettingsService.getContacts(settingsFilter);

      this.leadContactSelect = [];

      // populate leadContactSelect options
      contactsDTO.forEach((contact) => {
        this.leadContactSelect.push({
          text: contact.contactName,
          value: contact.contactID,
        });

        if (contact.receiveLeads) {
          this.$set(this.filter, "selected_contact", contact.contactID);
        }
      });

      this.existingContacts = contactsDTO;
    },
    async getSettings() {
      let company = this.$auth.activeCompany;

      let settingsFilter = {
        Company: { Companyid: company.companyId },
      };

      // make service call
      let settingsDTO = await SettingsService.getSettings(settingsFilter);

      // set properties
      this.prefix = settingsDTO.prefix;
      this.firstname = settingsDTO.firstName;
      this.middleinitial = settingsDTO.middleInitial;
      this.lastname = settingsDTO.lastName;
      this.suffix = settingsDTO.suffix;
      this.title = settingsDTO.title;
      this.address1 = settingsDTO.address;
      this.address2 = settingsDTO.address2;
      this.city = settingsDTO.city;
      this.state = settingsDTO.state;
      this.postalcode = settingsDTO.postalCode;
      this.country = settingsDTO.country;
      this.phone = settingsDTO.phone;
      this.phoneext = settingsDTO.phoneExt;
      this.phone2 = settingsDTO.phone2;
      this.phone2ext = settingsDTO.phone2Ext;
      this.cellphone = settingsDTO.cellPhone;
      this.tollfree = settingsDTO.tollFree;
      this.fax = settingsDTO.fax;
      this.password = settingsDTO.password;
      this.confirmpassword = settingsDTO.password;
      this.originalpassword = settingsDTO.password;
      this.emailaddress = settingsDTO.emailAddress;

      // mark as loaded
      this.loaded = true;

      //save settingsDTO
      this.existingSettings = settingsDTO;
    },
    async saveSettings() {
      this.validate("all");

      let formHasErrors = Object.values(this.formErrors).some((i) => i);

      // Check for form errors
      if (formHasErrors) {
        return;
      }

      // mark as reload
      this.reload = true;

      // get current companyif
      let companyid = this.$auth.activeCompany.companyId;

      let state = this.stateDTOs.filter((a) => a.state == this.state);
      if (state && state.length > 0) {
        this.stateid = state[0].stateID;
      }

      let country = this.countryDTOs.filter((a) => a.country == this.country);
      if (country && country.length > 0) {
        this.countryid = country[0].countryID;
      }

      let settingsPassword = "";

      // check to see if password changed
      if (
        this.originalpassword == this.password &&
        this.originalpassword == this.confirmpassword
      ) {
        settingsPassword = "";
      } else {
        settingsPassword = this.password;
      }

      let SettingDTO = {
        companyid: JSON.stringify(companyid),
        prefix: JSON.stringify(this.prefix),
        firstname: JSON.stringify(this.firstname),
        middleinitial: JSON.stringify(this.middleinitial),
        lastname: JSON.stringify(this.lastname),
        suffix: JSON.stringify(this.suffix),
        title: JSON.stringify(this.title),
        address: JSON.stringify(this.address1),
        address2: JSON.stringify(this.address2),
        city: JSON.stringify(this.city),
        state: JSON.stringify(this.state),
        stateid: JSON.stringify(this.stateid),
        postalcode: JSON.stringify(this.postalcode),
        country: JSON.stringify(this.country),
        countryid: JSON.stringify(this.countryid),
        cellphone: JSON.stringify(this.cellphone),
        phone: JSON.stringify(this.phone),
        phoneext: JSON.stringify(this.phoneext),
        phone2: JSON.stringify(this.phone2),
        phone2ext: JSON.stringify(this.phone2ext),
        fax: JSON.stringify(this.fax),
        password: JSON.stringify(settingsPassword),
        emailaddress: JSON.stringify(this.emailaddress),
        contactid: JSON.stringify(this.filter.selected_contact),
      };

      this.validate("all");

      if (!formHasErrors) {
        try {
          let postResult = await SettingsService.saveSettings(SettingDTO);
          if (postResult.success) {
            this.postSuccess = true;
            this.filePosted = true;
            await this.getSettings();
          } else {
            this.postSuccess = false;
            this.postError = true;
            this.filePosted = false;
          }
        } catch (e) {
          console.log(e);
          this.postSuccess = false;
          this.postError = true;
          this.filePosted = false;
        }
      }

      // mark reload as complete
      this.reload = false;
    },
  },
  computed: {
    showRegion() {
      return (
        this.country.length > 0 &&
        this.country != "United States" &&
        this.country != "Canada"
      );
    },
    showSaveCancelButtons() {
      return this.postSuccess ? false : true;
    },
  },
  async mounted() {
    // get countries
    this.getCountries();

    // get states
    this.getStates();

    // get prefixes
    this.getPrefixes();

    // get suffixes
    this.getSuffixes();

    // load contacts
    this.getContacts();

    // load account settings
    this.getSettings();

    TrackingService.track("viewed account settings");
  },
};
</script>
